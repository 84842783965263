<template>
  <div class="login-container">
    <el-row :gutter="60" style="margin-left: 0!important;margin-right: 0!important;">
      <el-col :span="12">
        <img src="@/assets/images/login/bg.png" alt="" style="width: 100%;height: 100%;">
      </el-col>

      <el-col :span="12">
        <div style="margin-top: 30px;">
          <div style="width: 100px;height: 100px;margin: auto;">
            <!--<img src="@/assets/images/login/logo.png" alt="" style="width: 100%;height: 100%;">-->
            <img :src="logo" alt="" style="width: 100%;height: 100%;">
          </div>

          <div style="width: 503px;height: 38px;margin: auto;margin-top: 30px;">
            <img src="@/assets/images/login/title.png" alt="" style="width: 100%;height: 100%;">
          </div>

          <el-row :gutter="15" style="text-align: center;margin-top: 50px;display: flex;justify-content: space-evenly;">
            <el-col :span="15">
              <el-form ref="form" :model="form" :rules="rules" label-width="100px" >
                <!--登录-->
                <div>
                  <div style="margin-top: 20px;">
                        <el-form-item label-width="0" prop="account">
                          <el-input
                              placeholder="请输入账号"
                              v-model="form.account"
                              clearable>
                          </el-input>
                        </el-form-item>
                      </div>
                      <div style="margin-top: 20px;">
                        <el-form-item label-width="0" prop="password">
                          <el-input
                              show-password
                              placeholder="请输入密码"
                              v-model="form.password"
                              clearable>
                          </el-input>
                        </el-form-item>
                      </div>
                      <div style="margin-top: 60px;">
                        <el-button style="background: #FEA837!important;border-color: #FEA837!important;color: #FFFFFF!important;width: 80%!important;" @click="handleSubmit()">登录</el-button>
                      </div>
                </div>
              </el-form>

            </el-col>
          </el-row>

        </div>
      </el-col>

    </el-row>
  </div>
</template>

<script>

import {
  AccountLoginApi,
  getSystemAdminInfoApi
} from '@/api/account'
import { setCookies } from '@/utils/cookies'
import Config from '@/config'
import { mapGetters } from 'vuex'

export default {
  name: 'new_login',
  data () {
    return {
      form: {
        account: '',
        password: '',
      },
      rules: {
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
      },
      //logo
      logo: this.$store.state.theme.logo,

    }
  },
  components: {},
  // 支柱道具属性
  props: {},
  // 计算属性 类似于 data 概念
  computed: {
    // 主页标题, 移除国际化上面template中使用:home-title="setting.homeTitle"
    homeTitle () {
      return '平台首页'
    },
    ...mapGetters(['theme', 'admin'])
  },
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created () {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted () {
      window.addEventListener("keyup", this.handleEnterKey);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.handleEnterKey);
  },
  // 方法集合
  methods: {
     handleEnterKey(event) {
      if (event.key === "Enter") {
        this.handleSubmit("formInline");
      }
    },
    // 提交表单
    handleSubmit () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const msg = this.$Message.loading({
            content: '登录中...',
            duration: 0
          });
          // 账号登录
          AccountLoginApi(this.form).then(async (res) => {
              if(res.code == 200){
                msg()
                let data = res.data
                let expires = this.getExpiresTime(data.expires_time)
                setCookies(Config.tokenStoreName, data.token, expires)
                setCookies('expires_time', data.expires_time, expires)
                this.$store.commit('admin/SET_USERSINFO', data.platform_info)
                this.$store.commit('admin/SET_UNIQUEAUTH', data.unique_auth)
                this.$store.commit('admin/SET_MENUS', data.menus)
                // 获取用户信息
                getSystemAdminInfoApi().then(res => {
                  localStorage.setItem('userInfo',JSON.stringify(res.data.platform_info));
                  return this.$router.push({ path: res.data.menus[0].children[0].path })
                }).catch(err => {
                  console.log(err)
                })
              }else {
                msg()
                this.$Message.error(res.msg)
              }
            }).catch(error => {
              msg()
              console.log(error)
              this.$Message.error(error.msg)
            })
        } else {
          msg()
          this.$Message.error('请确保必填项不为空哦! (oﾟωﾟo)')
        }
      })
    },
    getExpiresTime (expiresTime) {
      let nowTimeNum = Math.round(new Date() / 1000)
      let expiresTimeNum = expiresTime - nowTimeNum
      return parseInt(parseFloat(parseFloat(expiresTimeNum / 60) / 60) / 24)
    },
  },
  // 生命周期 - 更新之前
  beforeUpdate () {
  },
  // 生命周期 - 更新之后
  updated () {
  },
  // 过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate () {
  },
  // 生命周期 - 挂载之前
  beforeMount () {
  },
  // 生命周期 - 销毁之前
  beforeDestroy () {
  },
  // 生命周期 - 销毁完成
  destroyed () {
  },
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  // 进入的时候触发
  activated () {
  },
  // 离开的时候触发
  deactivated () {
  }
}
</script>

<style scoped lang="scss">
.login-container {
  //position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100vh;
  background: #FFFFFF !important;

  .left {
    position: relative;
    width: 515px;
    height: 460px;
    z-index: 1;
    img {
      width: 100%;
      //height: 100%;
    }
  }

  .page-account {
    position: relative;
    width: 415px;
    height: auto;
    z-index: 1;

    &__logo {
      font-size: 30px;
      text-align: center;
    }
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}
</style>
